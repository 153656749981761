export default {
  None:"None",
  Villager: "Villager",
  Werewolf: "Werewolf",
  Seer: "Seer",
  Robber: "Robber",
  Troublemaker: "Troublemaker",
  Tanner: "Tanner",
  Drunk: "Drunk",
  Hunter: "Hunter",
  Mason: "Mason",
  Insomniac: "Insomniac",
  Minion: "Minion",
  Doppelganger: "Doppelganger"
};
